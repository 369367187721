import React, { Component } from 'react'

// styles
import '../stylesheets/components/NoteInfo.scss';

class NoteInfo extends Component {
    render () {
        const {sum, name, number, date, cat} = this.props;
        return (
            <div className={'note-info'}>
                <header className="note-info__header">
                    <div className="note-info__sum">{sum} B,</div>
                    <div className="note-info__name">{name}</div>
                </header>
                <footer className="note-info__footer">
                    <div className="note-info__number">{number}</div>
                    <div className="note-info__date">{date}</div>
                    <div className="note-info__cat"> {cat}</div>
                </footer>
            </div>
        )
    }
}

export default NoteInfo