import React, { Component } from 'react'
import {Link} from 'react-router-dom';

// modules
import NavPanel from '../modules/NavPanel';
import Popup from '../modules/Popup';

// components
import Button from '../components/Button';
import Modal from '../components/Modal'; 

// icons
import {ReactComponent as WarningIcon} from '../assets/icons/warning.svg'
import {ReactComponent as BinIcon} from '../assets/images/bin.svg'
import {ReactComponent as NotiIcon} from '../assets/images/noti.svg'
import {ReactComponent as VoiceIcon} from '../assets/images/voice.svg'
import {ReactComponent as BackIcon} from '../assets/images/arrow-back.svg'


// styles
import '../stylesheets/pages/single-note.scss';

class SingleNote extends Component {
    state = {
        popupIsOpen: false,
        actionsPopupIsOpen: false
    }
    render () {
        return (
            <div className='single-note'>
                <NavPanel
                firstButton={
                    <Link to='/home'>
                        <BackIcon/>
                    </Link>
                }
                buttons={[
                    <BinIcon onClick={() => this.setState({popupIsOpen: true})}/>,
                    <NotiIcon/>,
                    <VoiceIcon/>
                ]}
                />


                <Popup 
                    isOpen={this.state.popupIsOpen}
                    onClose={() => this.setState({popupIsOpen: false})}
                    
                    content={
                        <Modal
                        icon={<WarningIcon/>}
                        title='Внимание!'
                        content='Вы пытаетесь удалить отчёт, подтвердив удаление вы не сможете восстановить его. Вы уверены что хотите удалить отчёт?'
                        />
                    }
                    buttons={[
                        <Button onClick={() => this.setState({popupIsOpen: false})}>
                            Нет, не удалять
                        </Button>,
                        <Button type='accent' onClick={() => this.setState({popupIsOpen: false})}>
                            Да, удалить
                        </Button>,
                    ]}
                />

                 <Popup 
                    isOpen={this.state.actionsPopupIsOpen}
                    onClose={() => this.setState({actionsPopupIsOpen: false})}
                    transparent
                    buttons={[
                        <Link to='/export'>
                            <Button type='accent' onClick={() => this.setState({actionsPopupIsOpen: false})}>
                                Экспортировать отчёт
                            </Button>
                        </Link>,
                        <Button type='accent' onClick={() => this.setState({actionsPopupIsOpen: false})}>
                            Сохранить как шаблон 
                        </Button>,
                        <Button onClick={() => this.setState({actionsPopupIsOpen: false})}>
                            Перенести в архив 
                        </Button>,
                        <Button onClick={() => this.setState({actionsPopupIsOpen: false})}>
                            Удалить отчёт
                        </Button>,
                        <Button  onClick={() => this.setState({actionsPopupIsOpen: false})}>
                            Отмена
                        </Button>,
                    ]}
                />
                <div className="default-container">
                    <header className="single-note__header">
                        <h2>Epam Systems</h2>
                        <p>Отчёт</p>
                    </header>

                    <ul className="object__fields">
                        <li className="object__field">
                            <div className="object__field-title">Дата</div>
                            <div className="object__field-value">12.06.2020 в 12:00</div>
                        </li>
                        <li className="object__field">
                            <div className="object__field-title">Назначение</div>
                            <div className="object__field-value">Назначение</div>
                        </li>
                        <li className="object__field">
                            <div className="object__field-title">Раздел</div>
                            <div className="object__field-value">Раздел</div>
                        </li>
                        <li className="object__field">
                            <div className="object__field-title">Подраздел</div>
                            <div className="object__field-value">Подраздел</div>
                        </li>
                        <li className="object__field">
                            <div className="object__field-title">Направление</div>
                            <div className="object__field-value">Направление</div>
                        </li>
                        <li className="object__field">
                            <div className="object__field-title">Раздел</div>
                            <div className="object__field-value">Раздел</div>
                        </li>
                        <li className="object__field">
                            <div className="object__field-title">Подраздел</div>
                            <div className="object__field-value">Подраздел</div>
                        </li>
                        <li className="object__field">
                            <div className="object__field-title">Направление</div>
                            <div className="object__field-value">Направление</div>
                        </li>
                    </ul>
                    
                    <Button 
                    type='accent' 
                    onClick={() => this.setState({actionsPopupIsOpen: true})}
                    className='single-note__edit'>Выбрать действие</Button>
                </div>
            </div>
        )
    }
}

export default SingleNote