import React, { Component } from 'react'
import classNames from 'classnames';
// icons
import {ReactComponent as CloseIcon} from '../assets/images/close.svg';
// styles
import '../stylesheets/modules/Popup.scss';

class Popup extends Component {
    render () {
        const { content, buttons, isOpen, className, onClose, transparent, hasTopOffset } = this.props;

        const classnames = classNames(
            'popup',
            {
                'popup--is-open': isOpen,
                'popup--transparent': transparent,
                'popup--has-top-offset': hasTopOffset,
            },
            className    
        )

        return (
            <div className={classnames}>
                <div className="popup__close" onClick={onClose}>
                    <CloseIcon/>
                </div>
                <div className="popup__content">
                    { content }
                </div>
                <div className="popup__footer">
                    {buttons && 
                        <div className="popup__buttons">
                            {buttons.map((button, i)=>
                                <div className="popup__button" key={i}>
                                    {button}
                                </div>
                            )}
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default Popup