import React, { Component } from 'react'

class InfoCard extends Component {
    constructor(props) {
        super(props);
        this.classes = props.className;
    }
    render () {
        return (
            <div className={`info-card ${this.classes}`}>
                <div className="info-card__header">баланс<span>бухгалтер</span></div>
                <div className="info-card__footer">
                    <span>756,000 B</span>
                    <span>756,000 B</span>
                </div>
            </div>
        )
    }
}

export default InfoCard