import { observable, computed , action, decorate } from "mobx"

class objectNoteStore {
  
    stages = [
        "Сумма",
        "Объект",
        "Назначение",
        "Раздел",
        "Подраздел",
        "Направление",
        'Дата',
        "Примeчание",
        "Баланс",
    ]
    
    members = [
        'Участник 1',
        "Участник 2",
        "Участник 3",
        "Участник 4",
        "Участник 5",
        "Участник 6",
        "Участник 7",
        "Участник 8",
        "Участник 8",
        "Участник 8",
        "Участник 8",
    ]

    assignments = [
        'Бизнес',
        "Приват",
        "Объект",
        "Рельса"
    ]

    sections = [
        'Секция 1',
        'Секция 2',
        'Секция 3',
        'Секция 4',
        'Секция 5',
        'Секция 6',
        'Секция 7',
        'Секция 8',
    ]

    subSections = [
        'Подраздел 1',
        'Подраздел 2',
        'Подраздел 3',
        'Подраздел 4',
    ]
    directions = [
        "Направление 1",
        "Направление 2",
        "Направление 3",
        "Направление 4",
        "Направление 5",
        "Направление 6",
    ]
    values5 = [
        "Примечание 1",
        "Примечание 2",
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys",
        "Примечание 4",
        "Примечание 5",
        "Примечание 6",
    ]

    activeStage = 0

    addMember = (member) => {
        this.members.push(member);
    }

    setMember = (member) => {
        this.userFields.member.value = member;
    }
    setAssignee = (assignee) => {
        this.userFields.assignee.value = assignee;
    }
    setSection = (section) => {
        this.userFields.section.value = section;
    }
    setSubSection = (subSection) => {
        this.userFields.subSection.value = subSection;
    }
    setDirection = (direction) => {
        this.userFields.direction.value = direction;
    }
    setDate = (date) => {
        this.userFields.date.value = date.toISOString();
    }
    setSum = (sum) => {
        this.userFields.sum.value = sum;
    }
    setComment = (comment) => {
        this.userFields.comment.value = comment.substr(0, 20) + '...'
    }
    setActiveStage = (stageIndex) => {
        this.activeStage = stageIndex;
    }

    get sumType () {
        if( parseInt(this.userFields.sum.value) > 0) {
            return false
        } else {
            return true
        }
    }

    get metainfo () {
        let filledFieldsCount = this.filledFields.length;
        let touchedFieldsCount = this.touchedFields.length;
        let totalFields = Object.keys(this.userFields).length;
        let progress = filledFieldsCount / totalFields * 100;
    
        return {
            progress: progress,
            totalFields: totalFields,
            filledFieldsCount: filledFieldsCount,
            touchedFieldsCount: touchedFieldsCount,
        }
    }

    get filledFields () {
        let arr = []
        for (let field in this.userFields) {
            let _field = this.userFields[field]
           
            if ( _field.value !== '' & _field.value !== '...') {
                arr.push({
                    viewName: _field.viewName,
                    value: _field.value,
                    id: _field.id
                })
            }
        }
        return arr
    }

    get touchedFields () {
        let arr = []
        for (let field in this.userFields) {
            let _field = this.userFields[field]
           
            if ( _field.value !== '') {
                arr.push({
                    viewName: _field.viewName,
                    value: _field.value,
                    id: _field.id
                })
            }
        }
        return arr
    }
    getCurrentDate = () => {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        
        return  mm + '.' + dd + '.' + yyyy
    }

    getFieldById = (id) => {
        let arr = [];
        for (let field in this.userFields) {
            let _field = this.userFields[field]
            arr.push({
                viewName: _field.viewName,
                value: _field.value,
                id: _field.id
            })
        }
        let result = arr.find((field, index, array) => {
            
            return field.id === id
        }) 

        return result
    }

    userFields = {
        sum: {
            id: 0,
            viewName: 'Сумма',
            value: '...'
        },
        object: {
            id: 1,
            viewName: 'Объект',
            value: '...'
        },
        assignee: {
            id: 2,
            viewName: 'Назначение',
            value: '...',
        },
        section: {
            id: 3,
            viewName: 'Раздел',
            value: '...',
        },
        subSection: {
            id: 4,
            viewName: 'Подраздел',
            value: '...',
        },
        direction: {
            id: 5,
            viewName: 'Направление',
            value: '...',
        },
        date: {
            id: 6,
            viewName: 'Дата',
            value: this.getCurrentDate(),
        },
        comment: {
            id: 7,
            viewName: 'Примечание',
            value: '...',
        },
        balance: {
            id: 8,
            viewName: 'Баланс',
            value: '...',
        }
    }
  
}

decorate(objectNoteStore, {
    userFields: observable,
    assignment: observable,
    members: observable,
    sections: observable,
    activeStage: observable,

    setDate: action,
    setMember: action,
    setSum: action,
    setAssignee: action,
    addMember: action,
    setSection: action,
    setSubSection: action,
    setDirection: action,
    setComment: action,
    setActiveStage: action,
    getCurrentDate: action,
    getFieldById: action,

    sumType: computed,
    metainfo: computed,
    filledFields: computed,
    touchedFields: computed,
})

export default new objectNoteStore()