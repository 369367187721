import React, { Component } from 'react'
import {Link} from 'react-router-dom';

// modules 
import NavPanel from '../modules/NavPanel';

// components 
import InputRange from '../components/InputRange'
import Button from '../components/Button';
import SmallCard from '../components/SmallCard';
import PercentRecord from '../components/PercentRecord';

// styles
import '../stylesheets/pages/contragent.scss';

// icons 
import {ReactComponent as BackIcon} from '../assets/images/arrow-back.svg';
import {ReactComponent as VoiceIcon} from '../assets/images/voice.svg';
import {ReactComponent as NotiIcon} from '../assets/images/noti.svg';
import {ReactComponent as Binicon} from '../assets/images/bin.svg';


class Contragent extends Component {
    render () {
        return (
            <div className='contragent'>
                <NavPanel
                firstButton={
                    <Link to='/home'>
                        <BackIcon/>
                    </Link>
                }
                buttons={
                    [
                        <Binicon/>,
                        <NotiIcon/>,
                        <VoiceIcon/>
                    ]
                }
                />
                <header className="contragent__header">
                    <div className='default-container'>
                        <div className='contragent__agent-info'>
                            <div className="contragent__name">Максим Никифоров</div>
                            <div className="contragent__role">Контрагент</div>
                        </div>
                        <div className="contragent__actual-percent">
                            <h3>текущий процент</h3>
                            <InputRange unit='%' min='5' max='20' />
                        </div>
                    </div>
                </header>
                <main className="default-container contragent__body">
                    <div className="contragent__add-note">
                        <h3>Записи по % периодам</h3>
                        <Link to='/agent-new-note'>
                        <div className="contragent__add-note-icon">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M9 1V17" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M1 9H17" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        </Link>
                    </div>
                    <div className="contragent__notes">

                        {/* <div className="contragent__no-notes">
                            У вас нет записей <br/> по процентным периодам
                        </div> */}
                        <Link to='agent-new-note'>
                            <SmallCard className='contragent__note'>
                                <PercentRecord percent='10' startDate='04.08.2020' endDate='24.08.2020' />
                            </SmallCard>
                        </Link>
                        <Link to='agent-new-note'>
                            <SmallCard className='contragent__note'>
                                <PercentRecord percent='5' startDate='04.08.2020' endDate='24.08.2020' />
                            </SmallCard>
                        </Link>
                        <Link to='/agent-new-note'>
                            <SmallCard className='contragent__note'>
                                <PercentRecord percent='15' startDate='04.08.2020' endDate='24.08.2020' />
                            </SmallCard>
                        </Link>
                    </div>
                </main>
                <footer className="contragent__footer">
                    <div className="default-container">
                        <Link to='/agent-new-note'>
                            <Button type='accent' className='contragent__btn'>Добавить запись % периода</Button>
                        </Link>
                    </div>
                </footer>
            </div>
        )
    }
}

export default Contragent