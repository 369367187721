import React, { Component } from 'react'
import '../stylesheets/components/Button.scss'
import classNames from 'classnames';

class Button extends Component {
    render () {
        const {size, type, children, className, onClick} = this.props;
        const classes = classNames(
            {
                'btn--sm': size === 'sm',
                'btn--danger': type === 'danger',
                'btn--accept': type === 'accept',
                'btn--accent': type === 'accent',
                'btn--disabled': type === 'disabled',
            },
            className
        )
        return (
            <div className={`btn ${classes}`} onClick={onClick}>
                {children}
            </div>
        )
    }
}

export default Button