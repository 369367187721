import React, { Component } from 'react'
import { Link } from 'react-router-dom';

// modules 
import NavPanel from '../modules/NavPanel';

// components 
import InputRange from '../components/InputRange'
import Input from '../components/Input';

// styles
import '../stylesheets/pages/ContragentCreateNote.scss';


// icons
import {ReactComponent as BackIcon} from '../assets/images/arrow-back.svg';
import {ReactComponent as CloseIcon} from '../assets/images/close.svg';

// helpers
import getCurrentDate from '../helpers/getCurrentDate';


class ContragentCreateNote extends Component {
    onSelectDate = (date) => {
        console.log(date)
    }
    render () {
        return (
            <div className='contragent-create-note'>
                <NavPanel
                firstButton={
                    <Link to='/contragent'>
                        <BackIcon/>
                    </Link>
                }
                buttons={
                    [
                    <Link to='/contragent'>
                        <CloseIcon/>
                    </Link>,
                    ]
                }
                title='Добавить % период'
                />
                <div className="contragent-create-note__body">
                    <div className="default-container">
                        <div className='contragent-create-note__agent-info'>
                            <div className="contragent-create-note__name">Максим Никифоров</div>
                            <div className="contragent-create-note__role">Контрагент</div>
                        </div>
                        <div className="contragent-create-note__date-box">
                            <Input title='От' className={'contragent-create-note__date'} defaultValue={getCurrentDate()} type='date'/>
                            <Input title='До' className={'contragent-create-note__date'} defaultValue={getCurrentDate()} type='date'/>
                        </div>
                        <div className="contragent-create-note__percent">
                            <h3>% в выбранном периоде</h3>
                            <InputRange unit='%' min='5' max='20' />
                        </div>
                    </div>
                </div>
                <footer className="contragent-create-note__footer">
                    <div className="default-container">
                        <div className="btn btn--accent contragent-create-note__save-btn">Сохранить</div>
                    </div>
                </footer>
            </div>
        )
    }
}

export default ContragentCreateNote