import React, { Component } from 'react'

// dependencies
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';

// modules
import NavPanel from '../modules/NavPanel';
import Popup from '../modules/Popup';

// components 
import InfoCard from '../components/InfoCard';
import Button from '../components/Button';
import Modal from '../components/Modal'; 
import SmallCard from '../components/SmallCard'; 
import NoteInfo from '../components/NoteInfo'; 

// icons
import {ReactComponent as WarningIcon} from '../assets/icons/warning.svg'
import {ReactComponent as BinIcon} from '../assets/images/bin.svg'
import {ReactComponent as NotiIcon} from '../assets/images/noti.svg'
import {ReactComponent as VoiceIcon} from '../assets/images/voice.svg'
import {ReactComponent as BackIcon} from '../assets/images/arrow-back.svg'

// styles
import '../stylesheets/pages/object.scss';
import 'swiper/swiper.scss';

class SingleObject extends Component {
    infoCards = [1,2,3,4,5];
    notes = [1,2,3,4,5,6];
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    constructor(props) {
        super(props)
        this.state = {
            popupIsOpen: false
        }
    }


    render () {
        return (
            <div className='object'>
                <NavPanel
                firstButton={
                    <Link to='/home'>
                        <BackIcon/>
                    </Link>
                }
                buttons={[
                    <BinIcon onClick={() => this.setState({popupIsOpen: true})}/>,
                    <NotiIcon/>,
                    <VoiceIcon/>
                ]}
                />


                <Popup 
                    isOpen={this.state.popupIsOpen}
                    onClose={() => this.setState({popupIsOpen: false})}
                    
                    content={
                        <Modal
                        icon={<WarningIcon/>}
                        title='Внимание!'
                        content='Вы пытаетесь удалить запись внутри объекта, подтвердив удаление вы не сможете восстановить ее. Вы уверены что хотите удалить запись?'
                        />
                    }
                    buttons={[
                        <Button onClick={() => this.setState({popupIsOpen: false})}>
                            Нет, не удалять
                        </Button>,
                        <Button type='accent' onClick={() => this.setState({popupIsOpen: false})}>
                            Да, удалить
                        </Button>,
                    ]}
                />

                <div className="default-container">
                    <div className="object__heading">
                        <h2>Cisco Systems</h2>
                        <p>Обновлено 15.09.2020</p>
                    </div>
                    <Swiper
                        spaceBetween={20}
                        slidesPerView={2}
                        className='object__info-cards'
                        >
                            {this.infoCards.map((item, index) => 
                                <SwiperSlide key={index}>
                                    <InfoCard className='object__info-card'/>
                                </SwiperSlide>
                            )}
                    </Swiper>
                    <ul className="object__fields">
                        <li className="object__field">
                            <div className="object__field-title">Дата</div>
                            <div className="object__field-value">12.06.2020 в 12:00</div>
                        </li>
                        <li className="object__field">
                            <div className="object__field-title">Назначение</div>
                            <div className="object__field-value">Назначение</div>
                        </li>
                        <li className="object__field">
                            <div className="object__field-title">Раздел</div>
                            <div className="object__field-value">Раздел</div>
                        </li>
                        <li className="object__field">
                            <div className="object__field-title">Подраздел</div>
                            <div className="object__field-value">Подраздел</div>
                        </li>
                        <li className="object__field">
                            <div className="object__field-title">Направление</div>
                            <div className="object__field-value">Направление</div>
                        </li>
                    </ul>
                           
                    <div className="object__notes">
                        <div className="object__notes-header">
                            <div className="object__notes-heading">Записи</div>
                            <div className="object__notes-change-list">Изменить список</div>
                        </div>
                        <ul className="object__notes-list">                 
                            {this.notes.map((item, index) => 
                                <Link to='/note' key={index}>
                                    <SmallCard className='object__note' darkIcon>
                                        <NoteInfo
                                        sum={'-123 0003'}
                                        name={'Пустовойтенко Константин Кон..'}
                                        number={'0' + index}
                                        date={'12.06.2021'}
                                        cat={'Оборудование'}
                                        />
                                    </SmallCard>
                                </Link>
                            )}
                        </ul>
                    </div>
                    <Link to='/create-object-note'>
                        <button className="btn btn--accent object__add-btn">Создать запись</button>
                    </Link>
                </div>
                
            </div>
        )  
    }
}

export default SingleObject