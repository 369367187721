import React, { Component } from 'react'
import {Link} from 'react-router-dom';

// modules
import NavPanel from '../modules/NavPanel';

// components 
import ObjectCard from '../components/ObjectCard';

// styles
import '../stylesheets/pages/objects.scss';

// icons
import {ReactComponent as BackIcon} from '../assets/images/arrow-back.svg'
import {ReactComponent as NotiIcon} from '../assets/images/noti.svg';
import {ReactComponent as VoiceIcon} from '../assets/images/voice.svg';

class Notifications extends Component {
    render () {
        return (
            <div className="objects">
                <NavPanel
                firstButton={
                    <Link to='/home'>
                        <BackIcon/>
                    </Link>
                }
                title='Объекты'
                
                buttons={[
                    <NotiIcon/>,
                    <VoiceIcon /> 
                ]}
                />
                <div className='objects__user-objects'>
                    <div className='default-container'>
                        <div className='user-objects'>
                            <div className="user-objects__tabs">
                                <div className="user-objects__tab user-objects__tab--is-active">В работе (6)</div>
                                <div className="user-objects__tab">Архив (2)</div>
                            </div>
                            <div className="user-objects__list objects__list">
                                <Link to='object'><ObjectCard/></Link>
                                <Link to='object'><ObjectCard/></Link>
                                <Link to='object'><ObjectCard/></Link>
                                <Link to='object'><ObjectCard/></Link>
                                <Link to='object'><ObjectCard/></Link>
                                <Link to='object'><ObjectCard/></Link>
                                <Link to='object'><ObjectCard/></Link>
                            </div>
                        </div>
                        <Link to='create-note'><button className="btn btn--accent objects__add-object">Добавить объект</button></Link>
                    </div>
                </div>
            </div>
            
        )
    }
}

export default Notifications