import React, { Component } from 'react'
import classNames from 'classnames';

// styles
import '../stylesheets/components/SmallCard.scss';

// icons
import {ReactComponent as More} from '../assets/images/dots-more.svg';

class SmallCard extends Component {
    state = {
        moreIsActive: false
    }

    toggleMoreOptions = (event) => {
        event.preventDefault();
        this.state.moreIsActive ? this.inactivateMoreOptions() : this.activateMoreOptions()
    }

    inactivateMoreOptions = () => {
        this.setState({moreIsActive: false})
    }

    activateMoreOptions = () => {
        this.setState({moreIsActive: true})
    }

    render () {
        const {children, className, darkIcon} = this.props;
        const { moreIsActive } = this.state;

        const classes = classNames(
            'small-card',
            {
                'small-card--dark-icon': darkIcon,
                'small-card--more-is-active': moreIsActive
            },
            className
        )
        
        return (
            <div className={classes} onBlur={this.inactivateMoreOptions}>
                <div className="small-card__more" onClick={this.toggleMoreOptions}>
                    <More/>
                    <ul className="small-card__options">
                        <li>Открыть</li>
                        <li>В архив</li>
                        <li>Удалить</li>
                    </ul>
                </div>

               

                <div className="small-card__content">
                    {children}
                </div>
            </div>
        )
    }
}

export default SmallCard