import React, { Component } from 'react'

// helpers
import classNames from 'classnames';

// components 

import CheckBox from '../components/CheckBox'; 
// icons 
import {ReactComponent as AddIcon } from '../assets/images/plus.svg';
import {ReactComponent as ResetIcon } from '../assets/images/close.svg';

class SearchList extends Component {
    constructor(props) {
        super(props);
        this.options = props.values;
        this.fieldName = props.fieldName;
        this.formData = props.formData;
    }
    state = {
        isOpen: false,
        userInputValue: '',
        userSelect: '',
        hasScrollbar: false,
        isActive: false
    };

    filtredOptions = () => {
        return this.options.filter( option => {
            return option.toLowerCase().includes(this.state.userInputValue.toLowerCase())
        })
    };
    
    inputChangeHandler = (e) => {
        this.setState({userInputValue: e.target.value}) 
    }

    inputClickHandler = () => {
        this.setState({
            isOpen: true
        })
    }

    reset = () => {
        this.setState({userInputValue: '', userSelect: ''}) 
        this.props.onSelect && this.props.onSelect('...');
    }

    optionClickHandler = (e) =>  {
        let option = e.target.innerHTML

        this.props.onSelect(option);
        this.setState({
            userInputValue: option,
            userSelect: option,
            isOpen: !this.state.isOpen
        })
    }

    openList() {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    render () {
        let filtredOptions = this.filtredOptions()
        const {placeholder, className, multiselect} = this.props;
        const { isOpen, hasScrollbar, userSelect, isActive } = this.state;

        const classes = classNames(
            'search-list',  
            {
                'search-list--is-open': isOpen,
                'search-list--has-scrollbar': hasScrollbar,
                'search-list--multi-select': multiselect,
                'search-list--is-active': isActive
            },
            className
        )
        return (
            <div className={ classes }>
                <div className="search-list__field">
                    <input 
                        type="text" 
                        onFocus={() => this.setState({isActive: true})} 
                        onChange={this.inputChangeHandler} 
                        onBlur={() => this.setState({isActive: false})}
                        placeholder={placeholder ? placeholder : 'Начните вводить'}
                        value={this.state.userInputValue}
                        className='search-list__input'
                    /> 
                
                    {userSelect ? (
                        <ResetIcon onClick={this.reset}/>
                    ) : multiselect ?  (
                        <div className="asd">asd</div>
                    ) : (
                        !filtredOptions.length && <AddIcon/>   
                    )}
                </div>
               {!userSelect &&
                <div className="search-list__options">
                    {filtredOptions.length > 0 ? (
                        <ul className="search-list__results">
                            {filtredOptions.map((option, index) => 
                                <li className='search-list__option'>
                                    {multiselect ? (
                                        <CheckBox value={option} label={option} id={option} name={option} key={index} />
                                    ) : (
                                        <p onClick={this.optionClickHandler} className="search-list__result-content">{option}</p>
                                    )
                                    }
                                </li>
                            )}
                        </ul>
                    ) : (
                        <p className="search-list__no-results">
                            Результаты отсутствуют, нажмите <span>+ </span>чтобы добавить новое значение
                        </p>
                    )
                    } 
                </div>
               }
            </div>
        )
    }
}

export default SearchList