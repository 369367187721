import React, { Component } from 'react'
import {Link} from 'react-router-dom';

// modules 
import NavPanel from '../modules/NavPanel';

// components
import ContragentCard from '../components/ContragentCard';
// import Button from '../components/Button';
import Input from '../components/Input';

// styles
import '../stylesheets/pages/contragents.scss';

// icons
import {ReactComponent as SearchIcon } from '../assets/icons/search.svg'
import {ReactComponent as NotiIcon} from '../assets/images/noti.svg'
import {ReactComponent as VoiceIcon} from '../assets/images/voice.svg'
import {ReactComponent as BackIcon} from '../assets/images/arrow-back.svg'

class Contragents extends Component {
  render () {
    
    return (

      <div className="contragents">
          <NavPanel
            firstButton={
                <Link to='/home'>
                    <BackIcon/>
                </Link>
            }
            title='Контрагенты'
            buttons={[
                <NotiIcon/>,
                <VoiceIcon/>
            ]}
          />
          <div className="default-container">
            <div className="contragents__header">
                <Input 
                type='search'

                title='поиск контрагента' 
                icon={<SearchIcon/>}/>
            </div>
            <div className="contragents__list">
              <Link to='/contragent'>
                <ContragentCard name='Максим Никифоров' proccent='12' className='contragents__agent-card'/>
              </Link>
              <Link to='/contragent'>
                <ContragentCard name='Максим Никифоров' proccent='12' className='contragents__agent-card'/>
              </Link>
              <Link to='/contragent'>
                <ContragentCard name='Максим Никифоров' proccent='12' className='contragents__agent-card'/>
              </Link>
              <Link to='/contragent'>
                <ContragentCard name='Максим Никифоров' proccent='12' className='contragents__agent-card'/>
              </Link>
              <Link to='/contragent'>
                <ContragentCard name='Максим Никифоров' proccent='12' className='contragents__agent-card'/>
              </Link>
              <Link to='/contragent'>
                <ContragentCard name='Максим Никифоров' proccent='12' className='contragents__agent-card'/>
              </Link>
              <Link to='/contragent'>
                <ContragentCard name='Максим Никифоров' proccent='12' className='contragents__agent-card'/>
              </Link>
              <Link to='/contragent'>
                <ContragentCard name='Максим Никифоров' proccent='12' className='contragents__agent-card'/>
              </Link>
            </div>

            {/* <Link to='/agent-new-note'>
              <Button type={'accent'} className='contragents__btn-add'>Добавить контрагента</Button>
            </Link> */}
          </div>
         
      </div>  
    )
  }
}

export default Contragents

