import React, { Component } from 'react'

// styles
import '../stylesheets/components/UserAvatar.scss';

class UserAvatar extends Component {
    render () {
        const { src } = this.props;
        return (
            <div className={'user-avatar'}>
                <div className="user-avatar__picture">
                    <img src={src} alt=""/>
                    <div className="user-avatar__status"></div>
                </div>
            </div>
        )
    }
}

export default UserAvatar