import React, { Component } from 'react'

// dependencies
import { Link } from 'react-router-dom';

// modules
import NavPanel from '../modules/NavPanel'

// components 
import Button from '../components/Button';
import Input from '../components/Input';

// icons
import {ReactComponent as VoiceIcon} from '../assets/images/voice.svg'

// styles
import '../stylesheets/pages/SignIn.scss';

class SignIn extends Component {
    render () {
        return (
            <div className={'sign-in'}>
                <NavPanel
                firstButton={
                    <div></div>
                }
                title='Авторизация'
                buttons={[
                    <VoiceIcon/>
                ]}
                />
                
                <div className='sign-in__main'>
                    <div className="default-container">
                        <div className="sign-in__form">
                            <div className="sign-in__fields">
                                <div className="sign-in__field">
                                    <Input title='номер телефона' type='tel' name='number'/>
                                </div>
                                <div className='sign-in__field'>
                                    <Input title='пароль' type='password' name='password'/>
                                </div>
                            </div>

                            <div className='sign-in__buttons'>
                                <Link to='/home'>
                                    <Button type='accent'>
                                        Войти
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>     
                </div>       
            </div>
        )
    }
}

export default SignIn