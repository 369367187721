import React, { Component } from 'react'

// dependencies
import { Link } from 'react-router-dom';

// modules
import NavPanel from '../modules/NavPanel'

// components 
import Button from '../components/Button';
import Input from '../components/Input';

// icons
import {ReactComponent as VoiceIcon} from '../assets/images/voice.svg'
import {ReactComponent as BackIcon} from '../assets/images/arrow-back.svg'

// styles
import '../stylesheets/pages/SignUpCode.scss';

class SignUpCode extends Component {
    render () {
        return (
            <div className={'sign-up-code'}>
                <NavPanel
                firstButton={
                    <Link to='/'>
                        <BackIcon/>
                    </Link>
                }
                title='Авторизация'
                buttons={[
                    <VoiceIcon/>
                ]}
                />
                
                <div className='sign-up-code__main'>
                    <div className="default-container">
                        <div className="sign-up-code__form">
                            <div className="sign-up-code__fields">
                                <div className="sign-up-code__field">
                                    <Input title='Введите код' type='number' name='number'/>
                                </div>
                                <p className="sign-up-code__paragraph">
                                    Отправили код на +7 917 000 00 00
                                </p>
                            </div>

                            <div className="sign-up-code__buttons">
                                <div className="sign-up-code__button">
                                    <Link to='/'>
                                        <Button >
                                            Отправить код повторно
                                        </Button>
                                    </Link>
                                </div>
                                <div className="sign-up-code__button">
                                    <Link to='/sign-in'>
                                        <Button type='accent'>
                                            Далее
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>     
                </div>       
            </div>
        )
    }
}

export default SignUpCode