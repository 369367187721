import React from 'react';
import ReactDOM from 'react-dom';
import {
    HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import {Provider} from 'mobx-react';




//  pages
import Home from './pages/Home';
import Notifications from './pages/Notifications';
import Objects from './pages/Objects';
import SingleObject from './pages/SingleObject';
import SingleNote from './pages/SingleNote';
import NewNote from './pages/NewNote';
import NewObjectNote from './pages/NewObjectNote';

import NewReport from './pages/NewReport';
import Contragents from './pages/Contragents';
import Contragent from './pages/Contragent';
import ContragentCreateNote from './pages/ContragentCreateNote';


import SignUp from './pages/SignUp';
import SignUpCode from './pages/SignUpCode';
import SignIn from './pages/SignIn';

import Export from './pages/Export';

//  styles 
import './index.scss';

//  store
import newNoteStore from './store/newNoteStore'; 
import newReportStore from './store/newReportStore'; 
import objectNoteStore from './store/objectNoteStore'; 


ReactDOM.render(
    <Router>
        <Switch>
            <Route exact path='/'>
                <SignUp/>
            </Route>
            <Route exact path='/sign-up/code'>
                <SignUpCode/>
            </Route>
            <Route exact path='/sign-in'>
                <SignIn/>
            </Route>
            
            <Route exact path="/home">
                <Home />
            </Route>
            <Route exact path="/user/notifications">
                <Notifications />
            </Route>
            <Route exact path="/objects">
                <Objects />
            </Route>
            <Route exact path="/export">
                <Export />
            </Route>
            <Route exact path="/object">
                <SingleObject />
            </Route>
            <Route exact path="/note">
                <SingleNote/>
            </Route>
            <Route exact path="/create-note">
                <Provider newNoteStore={newNoteStore}>
                    <NewNote/>
                </Provider>
            </Route>
            <Route exact path="/create-object-note">
                <Provider objectNoteStore={objectNoteStore}>
                    <NewObjectNote/>
                </Provider>
            </Route>
            <Route exact path="/new-report">
                <Provider newReportStore={newReportStore}>
                    <NewReport/>
                </Provider>
            </Route>
            <Route exact path="/contragents">
                <Contragents/>
            </Route>
            <Route exact path='/contragent'>
                <Contragent/>
            </Route>
            <Route exact path='/agent-new-note'>
                <ContragentCreateNote/>
            </Route>
            
        </Switch>
    </Router>,
    
  document.getElementById('root')
);
