import React, { Component } from 'react'

// dependencies
import { Link } from 'react-router-dom';

// modules
import NavPanel from '../modules/NavPanel'

// components 
import Button from '../components/Button';
import Input from '../components/Input';

// icons
import {ReactComponent as VoiceIcon} from '../assets/images/voice.svg'

// styles
import '../stylesheets/pages/SignUp.scss';

class SignUp extends Component {
    render () {
        return (
            <div className={'sign-up'}>
                <NavPanel
                firstButton={
                    <div></div>
                }
                title='Авторизация'
                buttons={[
                    <VoiceIcon/>
                ]}
                />
                
                <div className='sign-up__main'>
                    <div className="default-container">
                        <div className="sign-up__form">
                            <div className="sign-up__fields">
                                <div className="sign-up__field">
                                    <Input title='номер телефона' type='tel' name='number'/>
                                </div>
                                <p className="sign-up__paragraph">
                                    Мы отправим SMS с код-паролем на указанный номер
                                </p>
                            </div>

                            <div className='sign-up__buttons'>
                                <Link to='/sign-up/code'>
                                    <Button type='accent' >
                                        Получить код-пароль
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>     
                </div>       
            </div>
        )
    }
}

export default SignUp