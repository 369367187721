import React, { Component } from 'react'
import {Link} from 'react-router-dom';

//  components 
import ReportCard from '../components/ReportCard';
class UserReports extends Component {
    render () {
        return (
            <div className='user-reports'>
                <div className="user-reports__header">
                    <div className="user-reports__heading">Отчёты</div>
                    <Link to='/new-report'>
                        <div className="user-reports__add-object">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M9 1V17" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M1 9H17" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </Link>
                </div>
                <div className="user-reports__tabs">
                    <div className="user-reports__tab user-reports__tab--is-active">В работе (6)</div>
                    <div className="user-reports__tab">Шаблоны (2)</div>
                </div>
                <div className="user-reports__list">
                    <Link to='/note'><ReportCard/></Link>
                    <Link to='/note'><ReportCard/></Link>
                    <Link to='/note'><ReportCard/></Link>
                    <Link to='/note'><ReportCard/></Link>
                    <Link to='/note'><ReportCard/></Link>
                    <Link to='/note'><ReportCard/></Link>
                    <Link to='/note'><ReportCard/></Link>
                </div>
                <button className="btn user-reports__more">Все отчёты</button>
            </div>
        )
    }
}

export default UserReports