import React, { Component } from 'react'

import InputRange from 'react-input-range';

import 'react-input-range/lib/css/index.css'
import '../stylesheets/components/CustomRangeInput.scss'

class CustomRangeInput extends Component {
    render () {
        const { value, min, max, step, onChange, className, onChangeComplete } = this.props;
        return (
            <div className={`custom-range-input ${className}`}>
                
                <div className="custom-range-input__header">
                    <div className="custom-range-input__label custom-range-input__label--min">
                        <span>От</span>
                        {value.min}
                    </div>
                    <div className="custom-range-input__label custom-range-input__label--max">
                        <span>До</span>
                        {value.max}
                    </div>
                </div>
                
                <InputRange
                    minValue={min}
                    maxValue={max}
                    onChange={onChange && onChange}
                    onChangeComplete={onChangeComplete && onChangeComplete}
                    value={ value } 
                    step={step}
                />
            </div>
        )
    }
}

export default CustomRangeInput