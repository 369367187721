import React, { Component } from 'react'

// components
import RadioButton from '../components/RadioButton';
import Input from '../components/Input';

// icons
import {ReactComponent as RublIcon } from '../assets/images/rubl.svg'

class CalcField extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: '',
            minus: true 
        }
    }
    
    calc = (value) => {
        let val = value;
        this.setState({value: val});
        
        if(val === '') {
            val = '...'
        }  else {
            val = val + ' B'
        }
        
        this.props.onChange && this.props.onChange(val)
    }

    render () {
        return (
            <div className='calc-field'>
                <Input 
                value={this.state.value}
                title='Введите значение'
                onChange={this.calc} 
                name={this.props.fieldName} 
                className="calc-field__input" 
                autocomplete="off" 
                icon={<RublIcon/>}
                type="number"/>

                <div className="calc-field__type">
                    <RadioButton checked={this.props.sumType ? 'checked' : false} label='Расход' name='sum_type' value='plus' id='plus'/>
                    <RadioButton checked={!this.props.sumType ? 'checked' : false} label='Приход' name='sum_type' value='minus' id='minus'/>
                </div>
            </div>
        )
    }
}

export default CalcField