import React, { Component } from 'react'
import { Link } from "react-router-dom";

// components
import ObjectCard from '../components/ObjectCard';

class UserObjects extends Component {
    render () {
        return (
            <div className='user-objects'>
                <div className="user-objects__header">
                    <div className="user-objects__heading">Объекты</div>
                    <Link to='/create-note'>
                        <div className="user-objects__add-object">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M9 1V17" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M1 9H17" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </Link>
                </div>
                <div className="user-objects__tabs">
                    <div className="user-objects__tab user-objects__tab--is-active">В работе (6)</div>
                    <div className="user-objects__tab">Архив (2)</div>
                </div>
                <div className="user-objects__list">
                    <Link to='/create-object-note'>
                        <ObjectCard/>
                    </Link>
                    <Link to='/create-object-note'>
                        <ObjectCard/>
                    </Link>
                    <Link to='/create-object-note'>
                        <ObjectCard/>
                    </Link>
                    <Link to='/create-object-note'>
                        <ObjectCard/>
                    </Link>
                </div>
                <Link to='/objects'>
                    <button className="btn user-objects__more">Все объекты</button>
                </Link>
            </div>
        )
    }
}

export default UserObjects