import React, { Component } from 'react'

// dependencies
import { Link } from 'react-router-dom';

// modules
import NavPanel from '../modules/NavPanel'

// components 
import Button from '../components/Button';
import Input from '../components/Input';

// icons
import {ReactComponent as VoiceIcon} from '../assets/images/voice.svg'
import {ReactComponent as NotiIcon} from '../assets/images/noti.svg'
import {ReactComponent as IconBack} from '../assets/images/arrow-back.svg'

// styles
import '../stylesheets/pages/Export.scss';

class SignIn extends Component {
    render () {
        return (
            <div className={'sign-in'}>
                <NavPanel
                firstButton={
                    <Link to='/home'>
                        <IconBack/>
                    </Link>
                }
                title='Настройте экспорт'
                buttons={[
                    <NotiIcon/>,
                    <VoiceIcon/>
                ]}
                />
                
                <div className='sign-in__main'>
                    <div className="default-container">
                        <div className="sign-in__form">
                            <div className="sign-in__fields">
                                <div className="sign-in__field">
                                    <Input title='тип файла' name='file_type'/>
                                </div>
                                <div className='sign-in__field'>
                                    <Input title='пароль' name='password'/>
                                </div>
                            </div>

                            <Button type='accent'>
                                Экспортировать
                            </Button>
                        </div>
                    </div>     
                </div>       
            </div>
        )
    }
}

export default SignIn