import React, { Component } from 'react'
import { Link } from 'react-router-dom'

// modules
import NavPanel from '../modules/NavPanel';

// components 
import NotificationCard from '../components/NotificationCard';
import UserAvatar from '../components/UserAvatar';

// styles
import '../stylesheets/pages/notifications.scss';

// assets
import avatar from '../assets/images/prof_picture.jpg'

// icons
import {ReactComponent as VoiceIcon} from '../assets/images/voice.svg';

class Notifications extends Component {
    render () {
        return (
            <div className="notifications">
                <NavPanel
                firstButton={
                    <Link to='/home'>
                        <UserAvatar src={avatar}/>
                    </Link>
                }
                buttons={[
                    <VoiceIcon/>
                ]}
                />
                <div className="notifications__objects">
                   <div className='default-container'>
                        <div className='user-objects'>
                            <div className="user-objects__tabs">
                                <div className="user-objects__tab user-objects__tab--is-active">Новые (8)</div>
                                <div className="user-objects__tab">Просмотренные (2)</div>
                            </div>
                            <div className="user-objects__list">
                                <NotificationCard/>
                                <NotificationCard/>
                                <NotificationCard/>
                                <NotificationCard/>
                                <NotificationCard/>
                                <NotificationCard/>
                            </div>
                        </div>
                   </div>
                </div>
            </div>
            
        )
    }
}

export default Notifications