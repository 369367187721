import React, { Component } from 'react'
import classNames from 'classnames';

import '../stylesheets/components/Input.scss';

class Input extends Component {
    state = {
        isActive: false,
        filled: this.props.defaultValue
    }
    render () {
        const { type, className, icon, name, placeholder, title, onChange, defaultValue, ...props } = this.props;
        const { isActive, filled } = this.state;

        const classes = classNames(
            'input',
            {
                'input--search': type === 'search',
                'input--is-active': isActive || filled,
            },
            className
        )

        return (
            <div className={classes}>
                {title &&
                    <div className="input__title">{title}</div>
                }
                <div className="input__user-area">
                    <input 
                    className='input__input-tag'
                    name={name}
                    type={type}
                    onFocus={() => this.setState({isActive: true})}
                    onBlur={() => this.setState({isActive: false})}
                    onChange={
                        (e) => {
                            console.log(e.target.value);
                            if(e.target.value !== undefined && e.target.value !== '' ) {
                                this.setState({filled: true})
                            } else {    
                                this.setState({filled: false})
                            }
                            onChange && onChange(e.target.value)
                        }
                    } 
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    id={`id-${name}`} 
                    {...props}
                    />
                    
                    {icon && 
                        <div className="input__icon">
                            {icon}
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default Input