import React, { Component } from 'react'
import classNames from 'classnames';

// styles
import '../stylesheets/components/PercentRecord.scss';

class PercentRecord extends Component {
    state = {
        moreIsOpen: false
    }
    render () {
        const {className, percent, startDate, endDate} = this.props;

        const classes = classNames(
            'percent-record',
            {
                
            },
            className
        )
        return (
            <div className={classes}>
               <div className="percent-record__percent">{ percent + '%' }</div>
               <div className="percent-record__dates">
                   <div className="percent-record__date percent-record__first-date">
                        <span>от</span>
                        { startDate } 
                    </div>
                   <div className="percent-record__date percent-record__second-date"> 
                        <span>до</span>
                        { endDate } 
                    </div>
               </div>
            </div>
        )
    }
}

export default PercentRecord