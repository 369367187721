import React, { Component } from 'react'

class EditableField extends Component {
    constructor(props) {
        super(props);
        this.name = props.name;
        this.isFilled = () => {if(this.props.value === '...') {return false} else {return true}   }
        this.classes = props.className;
        this.onClick =  props.onClick;
        
    }
    
    render () {
       
        return (
            <div 
            className={`editable-field ${this.classes} 
            ${this.isFilled() ? 'editable-field--filled ' : 'editable-field--empty' }
            ${this.props.isActive ? 'editable-field--active' : ''}
            `} 
            onClick={this.onClick}>
                <div className="editable-field__icon">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.99998 13.4001C8.69736 13.4001 10.3252 12.7258 11.5255 11.5256C12.7257 10.3253 13.4 8.69748 13.4 7.0001C13.4 5.30271 12.7257 3.67485 11.5255 2.47461C10.3252 1.27438 8.69736 0.600098 6.99998 0.600098C5.30259 0.600098 3.67472 1.27438 2.47449 2.47461C1.27426 3.67485 0.599976 5.30271 0.599976 7.0001C0.599976 8.69748 1.27426 10.3253 2.47449 11.5256C3.67472 12.7258 5.30259 13.4001 6.99998 13.4001ZM9.96558 5.9657C10.1113 5.81482 10.1919 5.61273 10.1901 5.40298C10.1883 5.19322 10.1042 4.99257 9.95583 4.84424C9.8075 4.69592 9.60685 4.61178 9.3971 4.60996C9.18734 4.60814 8.98526 4.68877 8.83438 4.8345L6.19998 7.4689L5.16558 6.4345C5.01469 6.28877 4.81261 6.20814 4.60285 6.20996C4.3931 6.21178 4.19245 6.29592 4.04412 6.44424C3.89579 6.59257 3.81166 6.79322 3.80984 7.00298C3.80801 7.21273 3.88865 7.41482 4.03438 7.5657L5.63438 9.1657C5.7844 9.31567 5.98784 9.39993 6.19998 9.39993C6.41211 9.39993 6.61555 9.31567 6.76557 9.1657L9.96558 5.9657Z" fill="#78CBBB"/>
                </svg>

                </div>
                <div className="editable-field__name">{this.name}</div>
                <div className="editable-field__value">{this.props.value ? this.props.value : '...'}</div>
                <div className="editable-field__arrow"> 
                <svg width="6" height="10" viewBox="0 0 6 10" fill="none">
                    <path d="M3.17202 5.00008L0.343018 2.17208L1.75702 0.75708L6.00002 5.00008L1.75702 9.24308L0.343018 7.82808L3.17202 5.00008Z" fill="white" fill-opacity="0.32"/>
                </svg>
                </div>
            </div>
        )
    }
}

export default EditableField