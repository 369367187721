import React, { Component } from 'react'
import { Link } from 'react-router-dom';

// modules
import NavPanel from '../modules/NavPanel';

// store 
import {inject, observer} from 'mobx-react';
import Popup from '../modules/Popup';

// components
import {Swiper, Navigation, Pagination } from 'swiper';
import Button from '../components/Button';
import SearchList from '../components/SearchList'; 
import EditableField from '../components/EditableField';
import Input from '../components/Input';
import Modal from '../components/Modal';

// styles
import 'swiper/swiper.scss';
import '../stylesheets/components/editable-field.scss';
import '../stylesheets/pages/new-note.scss'

// icons 
import {ReactComponent as BackIcon} from '../assets/images/arrow-back.svg';
import {ReactComponent as NotiIcon} from '../assets/images/noti.svg';
import {ReactComponent as VoiceIcon} from '../assets/images/voice.svg';
import {ReactComponent as VoiceControlIcon} from '../assets/icons/voiceControl.svg';
import {ReactComponent as CrossIcon} from '../assets/icons/danger-cross.svg';
import {ReactComponent as AcceptIcon} from '../assets/icons/accepted.svg';

// helpers 
import getCurrentDate from '../helpers/getCurrentDate';

Swiper.use([Navigation, Pagination]);

@inject('newNoteStore')
@observer
class NewNote extends Component {
    constructor(props) {
        super(props)
        this.store = props.newNoteStore
        this.state = {
            listExpanded: false,
            activeStage: 0,
            voiceControlIsActive: false,
            popupIsOpen: false,
            popupCompleteIsOpen: false,
        }
        this.slider = {}
    }

    goToStage (index) {
        if(this.state.listExpanded) {
            this.expandList()
        }
        this.slider.slideTo(index)
    }

    expandList = () => {
        if(!this.state.listExpanded) {
            this.setState({
                listExpanded: true
            })
        } else {
            this.setState({
                listExpanded: false
            })
        }
       
    }
    renderTouchedFields = () => {
        let touchedFields = this.store.touchedFields;
        return touchedFields.map((field, i) => {
            let res  = this.store.activeStage === field.id
                return (
                    <div className="swiper-slide">
                         <EditableField 
                        name={field.viewName} 
                        key={field.id} 
                        isActive={res}
                        value={field.value} 
                        onClick={this.goToStage.bind(this, field.id)} 
                        />
                    </div>
                )
        })
    }           
        
    componentDidMount () {
        const self = this;
        this.slider = new Swiper('.form-stages', {
            slidesPerView: 1,
            observer: true, 
            followFinger: false,
            observeParents: true,
            navigation: {
                nextEl: '.new-note__nav-btn-2',
                prevEl: '.new-note__nav-btn-1',
                disabledClass: 'btn--disabled',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                renderBullet: function (i, className) {
                  return `<div class='${className} new-note__pagi-item-${i}'> ${self.store.stages[i]}</div>`;
                },
            },
            on: {
                slideChange: () => {
                    let activePagiElem = document.querySelector(`.new-note__pagi-item-${self.slider.activeIndex}`)
                    activePagiElem.scrollIntoView() 

                    self.fieldsSlider.slideTo(self.slider.activeIndex - 1)
                    self.store.setActiveStage(self.slider.activeIndex)
                }
            }
        })

        const getfieldsSliderSlidesPerView = () => {
            let quantity = 3;
            let height = window.innerHeight;
            if(height > 700) {
                quantity = 4
            } 
            if(height > 750) {
                quantity = 5
            }
            return quantity
        }

        this.fieldsSlider = new Swiper('.new-note__fields-slider', {
            direction: 'vertical',
            slidesPerView: getfieldsSliderSlidesPerView(),
            allowTouchMove: false,
        })
    }
   
    render () {
        const { voiceControlIsActive } = this.state;
        return (
            <div className='new-note'>
                <NavPanel
                firstButton={
                    <Link to='/home'>
                        <BackIcon/>
                    </Link>
                }
                title={!voiceControlIsActive ? (
                    <>
                         Создайте объект
                         <span>{this.store.metainfo.filledFieldsCount} значений из {this.store.metainfo.totalFields}</span>
                    </>
                 ) :  (
                     <VoiceControlIcon/>
                 )}
                buttons={[
                    <NotiIcon/>,
                    (
                        !voiceControlIsActive ? 
                        <VoiceIcon onClick={() => {this.setState({voiceControlIsActive: true})}}/> :
                        <CrossIcon onClick={() => {this.setState({voiceControlIsActive: false})}}/>
                    )
                    
                ]}
                />
                    <div className="new-note__progress-bar">
                        <span style={{width: this.store.metainfo.progress + '%'}} ></span>        
                    </div>
                    <div className='new-note__content'>
                        <header className={`new-note__header ${this.state.listExpanded ? 'new-note__header--expanded' : ''}`}>

                            <div className="swiper-container new-note__fields-slider">
                                <div className="swiper-wrapper">
                                    {this.renderTouchedFields()}
                                </div>
                            </div>

                            { this.store.metainfo.touchedFieldsCount > 3 &&
                                <div className="new-note__more-fields" onClick={this.expandList}>
                                    {this.state.listExpanded ? 'Свернуть' : 'Развернуть'} сводную
                                    <svg width="10" height="6" viewBox="0 0 10 6" fill="none">
                                        <path d="M5 6.00002L0.757004 1.75702L2.172 0.343018L5 3.17202L7.828 0.343018L9.243 1.75702L5 6.00002Z" fill="white"/>
                                    </svg>
                                </div>
                            } 
                        </header>
                        <main className="new-note__body">
                            <div className="swiper-pagination"></div>
                            
                            <form>
                                <div className="swiper-container form-stages">
                                    <div className="swiper-wrapper">

                                        <div className="swiper-slide">
                                            <Input title='Название объекта' />
                                        </div>

                                        <div className="swiper-slide">
                                            <SearchList 
                                            onSelect={this.store.setAssignee} 
                                            values={this.store.assignments} 
                                            fieldName='assignment' />
                                        </div>

                                        <div className="swiper-slide">
                                            <SearchList 
                                            onSelect={this.store.setSection}  
                                            values={this.store.sections} 
                                            fieldName='section' />
                                        </div>

                                        <div className="swiper-slide">
                                            <SearchList 
                                            onSelect={this.store.setSubSection}  
                                            values={this.store.subSections} 
                                            fieldName='subsection' />
                                        </div>

                                        <div className="swiper-slide">
                                            <SearchList 
                                            onSelect={this.store.setDirection}  
                                            values={this.store.directions} 
                                            fieldName='direction' />
                                        </div>

                                        <div className="swiper-slide">
                                            <Input title='Выберите дату'defaultValue={getCurrentDate()} type='date' name='object_date'/>
                                        </div>
                                        {/* <div className="swiper-slide">
                                            <Input title='примечание' onChange={this.store.setComment}/>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="new-note__fields-box">
                                                <Input title='по проекту' className='new-note__fields-box-field'/>
                                                <Input title='общий' className='new-note__fields-box-field'/>
                                            </div>
                                            <h5 className="new-note__title">* указаны балансы на момент создания данной записи</h5>
                                            <CheckBox 
                                            checked='checked'
                                            className={'new-note__checkbox'}
                                            label='Напомнить'
                                            name='remind'
                                            id='remind'
                                            />
                                        </div> */}
                                    </div>
                                    
                                </div>
                            </form>    
                        
                        </main>
                        <footer className="new-note__footer">
                            <div className="new-note__nav">
                                <div className="swiper-button-prev btn btn--inactive new-note__nav-btn new-note__nav-btn-1">Предыдущий шаг</div>
                                {!this.slider.isEnd ? (
                                    <div className="swiper-button-next btn new-note__nav-btn new-note__nav-btn-2">Следующий шаг</div> 
                                ) : (
                                    <Button 
                                    className={'new-note__nav-btn'} 
                                    type='accent'
                                    onClick={() => this.setState({popupIsOpen: true})}
                                    >
                                        Сохранить
                                    </Button>
                                )}
                            </div>
                        </footer>
                    </div>
                    <Popup 
                        isOpen={this.state.popupIsOpen}
                        hasTopOffset
                        transparent
                        onClose={() => this.setState({popupIsOpen: false})}
                        buttons={[
                            <Button type='accent' onClick={() => this.setState({popupIsOpen: false, popupCompleteIsOpen: true})}>
                                Создать объект 
                            </Button>,
                            <Button onClick={() => this.setState({popupIsOpen: false})}>
                                Создать и сохранить как шаблон 
                            </Button>,
                            
                            <Button  onClick={() => this.setState({popupIsOpen: false})}>
                                Отмена
                            </Button>,
                        ]}
                        />
                    <Popup 
                        isOpen={this.state.popupCompleteIsOpen}
                        onClose={() => this.setState({popupCompleteIsOpen: false})}
                        content={(
                            <Modal
                            icon={<AcceptIcon/>}
                            title='Объект создан успешно'
                            content='Вы успешно создали объект, вы можете найти его в разделе объекты'
                            />
                        )}
                        buttons={[
                            <Link to ='/home'>
                                <Button type='accent'>
                                    К списку объектов
                                </Button>
                            </Link>,
                        ]}
                    />
            </div>
        )
    }
   
}

export default NewNote