import React, { Component } from 'react'

class InputRange extends Component {
    constructor(props) {
        super(props);
        this.unit = props.unit;
        this.min = props.min;
        this.max = props.max;
    }
    state = {
        value: this.props.min
    }
    changeHandler = (e) => {
        this.setState({value: e.target.value})
    }
    render () {
        return (
            <div className='input-range'>
                <div className="input-range__header">
                    <div className="input-range__value">{this.state.value}</div>
                    <div className="input-range__unit">{this.unit}</div>
                </div>
                <div className="input-range__input-container">
                    <input type="range" min={this.min} max={this.max} onChange={this.changeHandler} value={this.state.value} className="input-range__input"/>
                    <h1>
                    

                    </h1>
                    <span style={{width: (this.state.value - this.min) / (this.max - this.min)  * 100 +  "%"}}></span>
                </div>
                <div className="input-range__footer">
                    <div className="input-range__min">{this.min} {this.unit}</div>
                    <div className="input-range__max">{this.max} {this.unit}</div>
                </div>
            </div>
        )
    }
}

export default InputRange