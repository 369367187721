import React, { Component } from 'react'

class NotificationCard extends Component {
    render () {
        return (
            <div className='notification-card'>
                <header className="notification-card__header">
                    <div className="notification-card__date">27.07.2020</div>
                    <div className="notification-card__more">
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
                            <circle cx="14" cy="14" r="14" fill="#1E1F26"/>
                            <path d="M14 15.75C14.9665 15.75 15.75 14.9665 15.75 14C15.75 13.0335 14.9665 12.25 14 12.25C13.0335 12.25 12.25 13.0335 12.25 14C12.25 14.9665 13.0335 15.75 14 15.75Z" fill="white"/>
                            <path d="M8.75 15.75C9.7165 15.75 10.5 14.9665 10.5 14C10.5 13.0335 9.7165 12.25 8.75 12.25C7.7835 12.25 7 13.0335 7 14C7 14.9665 7.7835 15.75 8.75 15.75Z" fill="white"/>
                            <path d="M19.25 15.75C20.2165 15.75 21 14.9665 21 14C21 13.0335 20.2165 12.25 19.25 12.25C18.2835 12.25 17.5 13.0335 17.5 14C17.5 14.9665 18.2835 15.75 19.25 15.75Z" fill="white"/>
                        </svg>
                    </div>
                </header>
                <main className="notification-card__body">
                    <p className="notification-card__message">
                        <span>Елена К.</span> отправила вам сумму, пожалуйста подтвердите <br/> или отклоните получение дохода.
                    </p>
                    <div className="notification-card__info">
                        <p>сумма</p>
                        <span>+ 200,000</span>
                    </div>
                </main>
                <footer className="notification-card__footer">
                    <div className="notification-card__buttons">
                        <button className='btn btn--sm btn--accept notification-card__btn'>Подтвердить</button>
                        <button className='btn btn--sm btn--danger notification-card__btn'>Отклонить</button>
                    </div>
                </footer>
            </div>
        )
    }
}

export default NotificationCard