import React, { Component } from 'react'

// styles
import '../stylesheets/components/Modal.scss'

class Modal extends Component {
    render () {
        const {title, content, icon} = this.props;
        return (
            <div className={`modal`}>
                <div className="modal__icon">
                    {icon}
                </div>
                <h2 className="modal__title">
                    {title}
                </h2>
                <p className="modal__content">
                    {content}
                </p>
            </div>
        )
    }
}

export default Modal