import React, { Component } from 'react'

class NavPanel extends Component {


    render () {
        const {title, firstButton, buttons} = this.props;
        return (
            <div className="nav-panel">
                { firstButton &&
                    firstButton
                }
                {title && 
                    <div className="nav-panel__title">{title}</div>
                }
                {buttons &&
                    <div className="nav-panel__buttons">
                        {buttons.map((button, index) => 
                            <div className="nav-panel__button" key={index}>
                                {button}
                            </div>
                        )}
                    </div>
                }
            </div>
        )
    }
}

export default NavPanel