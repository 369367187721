import React, { Component } from 'react'
import classNames from 'classnames';

class CheckBox extends Component {
    state = {
        checked: false
    }

    changeHandler = () => {
        console.log('asd');
        this.setState({checked: !this.state.checked})
    }

    render () {
        const { className, id, name, label, value } = this.props;
        const { checked } = this.state;

        const classes = classNames(
            'checkbox', 
            {
                'checkbox--active': checked
            },
            className
        )
        return (
            <div className={ classes }>
                <input onChange={this.changeHandler} value={value} checked={checked} className='checkbox__input' type="checkbox" id={id} name={name} />
                <label className='checkbox__label' htmlFor={id}>{label}</label>
            </div>
        )
    }
}

export default CheckBox