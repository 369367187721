import React, { Component } from 'react'

class RadioButton extends Component {
    render () {
        return (
            <div className='radio-button'>
                <input checked={this.props.checked} className='radio-button__input' type="radio" value={this.props.value} id={this.props.id} name={this.props.name}/>
                <label htmlFor={this.props.id} className='radio-button__label'>{this.props.label}</label>
            </div>
        )
    }
}

export default RadioButton