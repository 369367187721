import React, { Component } from 'react'
import { Link } from 'react-router-dom'
// modules 
import NavPanel from '../modules/NavPanel';

//  blocks
import UserObjects from '../blocks/UserObjects';
import UserReports from '../blocks/UserReports';

// dependencies
import { Swiper, SwiperSlide } from 'swiper/react';

// components
import InfoCard from '../components/InfoCard';
import UserAvatar from '../components/UserAvatar';

// styles
import 'swiper/swiper.scss';
import '../stylesheets/pages/home.scss';


// assets 
import avatar from '../assets/images/prof_picture.jpg'

// icons
import {ReactComponent as NotiIcon} from '../assets/images/noti.svg';
import {ReactComponent as AgentsIcon} from '../assets/icons/team.svg';
import {ReactComponent as VoiceIcon} from '../assets/images/voice.svg';


class Home extends Component {
  infoCards = [1,2,3,4,5];
  render () {
    
    return (

      <div className="home">
          <NavPanel
          firstButton={
            <UserAvatar src={avatar}/>
          }
          buttons={[
            <Link to='/contragents'>
              <AgentsIcon/>
            </Link>,
            <Link to='/user/notifications'>
              <NotiIcon/>
            </Link>,
            <VoiceIcon/>
          ]}
          />
          <div className="default-container">
            <div className="home__greeting">
                <h2>Привет, Никита</h2>
                <p>У вас 8 новых уведомлений</p>
            </div>
            <Swiper
                spaceBetween={12}
                slidesPerView={3.3}
                className='home__info-cards'
                >
                  {this.infoCards.map(() => 
                      <SwiperSlide>
                          <InfoCard/>
                      </SwiperSlide>
                  )}
            </Swiper>   
          </div>
          <div className="home__objects">
            <div className="default-container">
              <UserObjects/>
            </div>
          </div>
          <div className="home__reports">
            <div className="default-container">
              <UserReports/>
            </div>
          </div>
      </div>  
    )
  }
}

export default Home

